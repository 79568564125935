<template>
    <div class="user-wrap">
      <div class="user-left">
        <div class="user-title">Article Moderation</div>
        <div class="user-nav">
          <li><router-link :to="{path: '/management/pending', query: {uid: user.uid, admin: user.admin}}"><div :class="{active: sideNav[0]}">Pending</div></router-link></li>
          <li><router-link :to="{path: '/management/approved', query: {uid: user.uid, admin: user.admin}}"><div :class="{active: sideNav[1]}">Approved</div></router-link></li>
          <li><router-link :to="{path: '/management/disapproved', query: {uid: user.uid, admin: user.admin}}"><div :class="{active: sideNav[2]}">Disapproved</div></router-link></li>
          <el-divider />
        </div>
        <div class="user-title">Comment Moderation</div>
        <div class="user-nav">
          <li><router-link :to="{path: '/management/pendingComment', query: {uid: user.uid}}"><div :class="{active: sideNav[3]}">Pending</div></router-link></li>
          <li><router-link :to="{path: '/management/moderatedComment', query: {uid: user.uid}}"><div :class="{active: sideNav[4]}">Moderated</div></router-link></li>
        </div>
      </div>
      <div class="user-right">
        <router-view/>
      </div>
    </div>
  </template>
  
  <script>
  import {onUpdated, ref, watch} from "vue";
  import {useRoute} from 'vue-router'
  import {post} from "@/api";
  export default {
    name: "Management",
    setup() {
      const sideNav = ref([false, false, false, false, false])
      const user = ref({})
      const route = useRoute()
      checkRoute(route.name)
      watch(() => route.name, (to) => {
        checkRoute(to)
      })
      function checkRoute(route) {
        switch (route) {
          case 'Pending':
            sideNav.value = [true, false, false, false, false]
            break;
          case 'Approved':
            sideNav.value = [false, true, false, false, false]
            break;
          case 'Disapproved':
            sideNav.value = [false, false, true, false, false]
            break;
          case 'PendingComment':
            sideNav.value = [false, false, false, true, false]
            break;
          case 'ModeratedComment':
            sideNav.value = [false, false, false, false, true]
            break;
        }
      }
  
      function checkLogin() {
        // console.log(sessionStorage.getItem("token"))
        if (sessionStorage.getItem("token")) {
          post(`/user/logged`).then(res => {
            // console.log(res)
            if (res.res == 1) {
              // console.log("run")
              user.value = res.user
              // console.log(user.value.uname)
            } else {
              sessionStorage.removeItem("token")
            }
          })
        }
      }
      checkLogin()
      onUpdated(()=> {
  
      })
      return{
        sideNav,
        user
      }
    }
  }
  </script>
  
  <style scoped>
  .user-wrap{
    display: flex;
    width: 1240px;
    margin: 25px auto 45px auto;
    justify-content: space-between;
  }
  .user-left{
    flex: 0 0 240px;
    width: 240px;
    min-height: 580px;
    border-radius: 30px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.1);
  }
  .user-right{
    flex: 0 0 965px;
    width: 965px;
    min-height: 580px;
    border-radius: 30px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.1);
    /*border: 1px solid red;*/
  }
  .user-title{
    display: block;
    margin-top: 40px;
    width: 100%;
    color: #111880;
    text-align: center;
    font-size: 20px;
    font-family: "Poppins-ExtraBold";
  }
  .user-info{
    display: block;
    width: 100%;
    margin-top: 22px;
    text-align: center;
  }
  .user-info .ui-face{
    flex: 0 0 128px;
    display: block;
    margin: 0 auto;
    width: 128px;
    height: 128px;
  }
  .user-info .ui-face img{
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border:6px solid #5473FF;
  }
  .user-info .ui-name{
    font-size: 20px;
    font-family: "Poppins-SemiBold";
    margin-bottom: 6px;
    color: #111880;
    margin-top: 20px;
  }
  .user-info .ui-integral{
    display: flex;
    align-items: center;
    margin-top: 15px;
    font-size: 16px;
    color: #5473FF;
    justify-content: center;
  }
  .user-info .ui-integral img{
    width: 19px;
    height: 19px;
    margin-right: 8px;
  }
  .user-nav{
    display: block;
    width: 100%;
    margin-top: 26px;
  }
  .user-nav li{
    display: block;
    width: 100%;
    margin: 10px 0;
  }
  .user-nav li div{
    display: block;
    width: 100%;
    font-size: 16px;
    height: 55px;
    line-height: 55px;
    color: #111880;
    font-family: "Poppins-SemiBold";
    background-color: #FFFFFF;
    text-align: center;
    border-radius: 30px;
  }
  .user-nav li.cur div,.user-nav li:hover div{
    color: #FFFFFF;
    background-color: #5473FF;
  }
  .user-nav .active{
    color: #FFFFFF;
    background-color: #5473FF;
  }
  </style>